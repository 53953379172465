<template>
  <div class="ReferenceTrainChangeMain">
    <v-dialog v-model="dialog" max-width="1280" persistent :fullscreen="$vuetify.breakpoint.xs">
      <template v-slot:activator="{ on }">
        <v-row class="pa-4 ma-0 align-center">
          <v-col align-center py-0 md="1" sm="1" cols="4">
            <div class="d-flex justify-center">
              <v-dialog v-model="referenceDialog" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon plain v-bind="attrs" v-on="on">
                    <v-icon color="grey" x-large left>mdi-text-box-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <v-card justify-center>
                  <v-img height="100%" src="../../../public/img/ChangeSchaldue.png" />
                  <v-card-actions class="align-items-center justify-space-around flex-wrap">
                    <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeReferenceDialog">Закрыть</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col md="7" sm="6" cols="8">
            <div class="grey--text text-none mr-3">Справка</div>
            <div>об изменении графика движения поездов</div>
          </v-col>
          <v-col md="2" sm="3" cols="6">
            <div class="d-flex align-center justify-center mr-2">
              <h2 class="Price">0 руб.</h2>
            </div>
          </v-col>
          <v-col md="2" min-width="150" sm="2" cols="6">
            <div>
              <v-btn v-on="on" class="white--text mr-3 text-RussianRail" depressed color="#e21a1a">Выбрать</v-btn>
            </div>
          </v-col>
        </v-row>
        <hr class="Line" />
      </template>
      <v-card class="file-upload-card px-4" :color="$vuetify.breakpoint.xs ? '#ffffff' : '#d3d3d3'" outlined>
        <v-form ref="productForm" @submit.prevent="checkInput">
          <v-row>
            <v-col>
              <v-card :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <!-- <v-card-title class="justify-center">Оформление заказа</v-card-title> -->
                <v-card-title class=" d-flex justify-center Header">Справка об изменении графика движения поездов</v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <hr class="d-flex d-sm-none mx-3" />
          <v-row>
            <v-col class="DataOfTicketCol">
              <v-card height="100%" min-width="230px" :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-title class="Header">Введите данные</v-card-title>
                <!-- <div class="d-flex flex-wrap justify-space-around"> -->
                <v-card-text>
                  <v-autocomplete
                    v-model="order.reference.arrivalStationCode"
                    :rules="notEmptyRules"
                    :items="stationList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    hint="Выберите станцию прибытия"
                    label="Станция прибытия"
                  ></v-autocomplete>
                  <v-text-field
                    v-on:keyup="showInUpperCase"
                    v-model="order.reference.trainNumber"
                    :rules="notEmptyRules.concat(validationRules.checkTrainNumber)"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Введите номер поезда"
                    label="Номер поезда"
                    maxlength="4"
                    minlength="4"
                    ><template v-slot:append-outer>
                      <v-menu open-on-hover top offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <v-list>
                          <v-list-item>
                            <a href="https://www.rzd.ru/ru/9319" target="_blank">Фирменные поезда</a>
                          </v-list-item>
                          <v-list-item>
                            <a href="https://www.rzd.ru/ru/9317" target="_blank">Классы обслуживания</a>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template></v-text-field
                  >
                  <v-menu v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        persistent-hint
                        :rules="notEmptyRules"
                        hint="Выберите дату прибытия"
                        label="Дата прибытия"
                        v-model="visibleDateFrom"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="order.reference.departDate"
                      :max="showMaxDate"
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      no-title
                      color="#e21a1a"
                      @input="datePickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                    v-model="order.comment"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    hint="Введите комментарий (при необходимости)"
                    label="Комментарий"
                  ></v-text-field>
                </v-card-text>
                <!-- </div> -->
              </v-card>
            </v-col>
            <v-col>
              <hr class="d-flex d-sm-none mx-3" />
              <v-card height="100%" min-width="230px" :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-title class="Header">Введите контактные данные</v-card-title>
                <v-card-text>
                  <span class="font-weight-medium">Получатель</span>
                  <v-text-field
                    v-model="order.fullName"
                    :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
                    autocomplete="name"
                    hide-details="auto"
                    class="mt-1 mb-0"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    label="Отображаемое имя"
                    hint="Введите данные контактного лица"
                  ></v-text-field>
                  <p class="nameDescription">
                    Введите фамилию и инициалы в соответствии с документом на который оформляется проезд.
                    <span class="warningSpan"
                      >*
                      <br />
                      Если ФИО в документе указаны на латинице - указать фамилию и инициалы на латинице.
                    </span>
                  </p>
                  <span class="font-weight-medium">Почта</span>
                  <v-text-field
                    v-model="order.mail"
                    :rules="validationRules.notEmpty.concat(validationRules.emailForm)"
                    autocomplete="email"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    type="mail"
                    label="Email"
                    persistent-hint
                    hint="Введите почту"
                  ></v-text-field>
                  <span class="font-weight-medium">Телефон</span>
                  <v-text-field
                    v-model="order.phone"
                    :rules="validationRules.numberForm"
                    autocomplete="tel"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    type="number"
                    persistent-hint
                    hint="Введите номер телефона"
                    label="Номер"
                    min="0"
                    hide-spin-buttons
                  ></v-text-field>
                </v-card-text>
                <div class="BannedStation" v-if="isStationBanned" color="red">
                  Для получения справки по поездам сообщением с Республикой Крым и г. Севастополем необходимо обратиться к перевозчикам АО ТК «Гранд Сервис
                  Экспресс» и ООО «ЮППК».
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <hr class="d-flex d-sm-none mx-3" />
              <v-card :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-actions class="align-items-center justify-space-around flex-wrap">
                  <v-col>
                    <v-btn
                      :loading="loading"
                      :disabled="!enabled || isStationBanned"
                      type="submit"
                      depressed
                      color="#e21a1a"
                      class="white--text mr-3 text-RussianRail"
                      v-on:click="createOrderClick"
                      >Заказать бесплатно</v-btn
                    >
                    <div>
                      <v-checkbox v-model="enabled" label="Согласие с условиями оферты" hide-details class="shrink mr-2 mt-0"></v-checkbox>
                      <a href="Оферта.pdf" target="_blank">Условия оферты</a>
                    </div>
                  </v-col>
                  <v-btn text class="mr-3 text-RussianRail" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="freeDialog" max-width="1024" persistent>
      <v-card class="file-upload-card px-4" outlined>
        <div>
          Справка заказана. Оплата не требуется.
        </div>
        <v-btn type="submit" depressed color="#e21a1a" class="white--text mr-3 text-RussianRail" v-on:click="closeFreeDialog">Закрыть</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import moment from 'moment';

export default {
  props: {
    user: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      order: {
        fullName: '',
        mail: '',
        phone: '',
        referenceType: 5,
        reference: {
          arrivalStationCode: '',
          departDate: '',
          trainNumber: '',
          trainType: ''
        }
      },
      validationRules: {
        notEmpty: [v => !!v || 0 || 'Обязательное поле'],
        emailForm: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        nameForm: [
          value => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        numberForm: [v => (v.length > 10 && v.length < 12) || 'Проверьте правильность указанного номера'],
        checkTrainNumber: [
          value => {
            const pattern1 = /^((\d{4}))$/;
            const pattern2 = /^(\d{3})$/;
            const pattern3 = /^((\d{3})+([А-я])?)$/;
            return pattern1.test(value) || pattern2.test(value) || pattern3.test(value) || 'Неверный формат';
          }
        ]
      },
      dialog: false,
      freeDialog: false,
      referenceDialog: false,
      loading: false,
      datePickerMenu: false,
      notEmptyRules: [v => !!v || 0 || 'Обязательное поле'],
      enabled: false
    };
  },
  computed: {
    showPresentDate() {
      return moment()
        .format()
        .substring(0, 10);
    },
    showMaxDate() {
      return moment()
        .add(120, 'd')
        .format()
        .substring(0, 10);
    },
    visibleDateFrom() {
      return this.changeDateForm(this.order.reference.departDate);
    },
    isStationBanned() {
      let isBanned = false;
      if (this.order.reference.departStationCode >= 2078000 && this.order.reference.departStationCode <= 2079999) {
        isBanned = true;
      }
      if (this.order.reference.arrivalStationCode >= 2078000 && this.order.reference.arrivalStationCode <= 2079999) {
        isBanned = true;
      }
      return isBanned;
    },
    ...mapGetters('reference', ['createReferenceResult']),
    ...mapGetters('reference', ['createReferenceMessage']),
    ...mapGetters('reference', ['paymentToken']),
    ...mapGetters('reference', ['orderNumber'])
  },
  methods: {
    showInUpperCase() {
      this.order.reference.trainNumber = this.order.reference.trainNumber.toUpperCase();
    },
    stationList() {
      return ReferenceHelper.getStationList();
    },
    tariffList() {
      return ReferenceHelper.getTariffTypeList();
    },
    carList() {
      return ReferenceHelper.getCarTypeList();
    },
    seatList() {
      return ReferenceHelper.getSeatTypeList();
    },
    checkInput() {
      return true;
    },
    getDay(date) {
      const daysOfWeek = ReferenceHelper.getDateList();
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    createOrderClick() {
      if (this.$refs.productForm.validate()) {
        this.createOrder({ order: this.order }).then(() => {
          if (this.createReferenceResult === true) {
            this.closeDialog();
            this.showFreeDialog();
            //this.$router.push('/payment?token=' + this.paymentToken);
          }
        });
      }
    },
    closeDialog() {
      this.order.fullName = '';
      this.order.mail = '';
      this.order.phone = '';
      this.order.reference.departDate = '';
      this.order.reference.carType = '';
      this.order.reference.arrivalStationCode = '';
      this.dialog = false;
      this.enabled = false;
    },
    showFreeDialog() {
      this.freeDialog = true;
    },
    closeFreeDialog() {
      this.freeDialog = false;
    },
    closeReferenceDialog() {
      this.referenceDialog = false;
    },
    changeDateForm(pickerDate) {
      return pickerDate
        .split('-')
        .reverse()
        .join('.');
    },
    ...mapActions('reference', ['createOrder'])
  }
};
</script>
<style>
.nameDescription {
  font-size: 0.85em;
  letter-spacing: 0.1px;
  line-height: 1.2;
}
.warningSpan {
  color: red;
}
.Price {
  white-space: nowrap;
}
.Header {
  font-family: FsRail;
}
.BannedStation {
  color: red;
  padding: 15px;
}
.ReferenceTrainChangeButtonText {
  color: #e21a1a;
}
.DataOfTicketCol {
  flex-basis: 30%;
}
.file-upload-card {
  padding: 20px;
  /* background-color: rgb(211, 211, 211); */
  font-family: RussianRail;
}
.Line {
  margin-left: 3%;
  margin-right: 3%;
}
.ReferenceTrainChangeButtonWrapper {
  border: solid 1px #e21a1a;
  border-radius: 6px;
  width: min-content;
}
</style>
