<template>
  <div class="ReferenceTravelCostMain">
    <v-dialog v-model="dialog" max-width="1280" persistent :fullscreen="$vuetify.breakpoint.xs">
      <template v-slot:activator="{ on }">
        <v-row class="pa-4 ma-0 align-center">
          <v-col align-center py-0 md="1" sm="1" cols="4">
            <div class="d-flex justify-center">
              <v-dialog v-model="referenceDialog" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon plain v-bind="attrs" v-on="on">
                    <v-icon color="grey" x-large left>mdi-text-box-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <v-card justify-center>
                  <v-img height="100%" src="../../../public/img/Price.png" />
                  <v-card-actions class="align-items-center justify-space-around flex-wrap">
                    <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeReferenceDialog">Закрыть</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col md="7" sm="6" cols="8">
            <div class="grey--text text-none mr-3">Справка</div>
            <div>о стоимости проезда</div>
          </v-col>
          <v-col md="2" sm="3" cols="6">
            <div class="d-flex align-center justify-center mr-2">
              <h2 class="Price">200 руб.</h2>
            </div>
          </v-col>
          <v-col md="2" min-width="150" sm="2" cols="6">
            <v-btn v-on="on" class="white--text mr-3 text-RussianRail" depressed color="#e21a1a">Выбрать</v-btn>
          </v-col>
        </v-row>
        <hr class="Line" />
      </template>
      <v-card class="file-upload-card" :color="$vuetify.breakpoint.xs ? '#ffffff' : '#d3d3d3'" outlined>
        <v-form ref="productForm" @submit.prevent="checkInput">
          <v-row>
            <v-col>
              <v-card :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <!-- <v-card-title class="justify-center">Оформление заказа</v-card-title> -->
                <v-card-title class="d-flex justify-center Header">Справка о стоимости проезда</v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <hr class="d-flex d-sm-none mx-3" />
          <v-row>
            <v-col class="DataOfTicketCol">
              <v-card height="100%" min-width="230px" :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-title class="Header">Введите данные</v-card-title>
                <v-card-text>
                  <v-autocomplete
                    v-model="order.reference.departStationCode"
                    :rules="notEmptyRules"
                    :items="stationList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Выберите станцию отправления"
                    label="Станция отправления"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="order.reference.arrivalStationCode"
                    :rules="notEmptyRules"
                    :items="stationList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Выберите станцию прибытия"
                    label="Станция прибытия"
                  ></v-autocomplete>
                  <v-select
                    v-model="order.reference.seatType"
                    :rules="notEmptyRules"
                    :items="seatList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Выберите тип места"
                    label="Тип места"
                    type="number"
                  ></v-select>
                  <v-text-field
                    v-on:input="onChangeTrainNumber()"
                    v-model="order.reference.trainNumber"
                    :rules="notEmptyRules.concat(validationRules.checkTrainNumber)"
                    hide-details="auto"
                    v-on:keyup="showInUpperCase"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Введите номер поезда"
                    label="Номер поезда"
                    maxlength="4"
                    minlength="4"
                    ><template v-slot:append-outer>
                      <v-menu open-on-hover top offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs">
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <v-list>
                          <v-list-item>
                            <a href="https://www.rzd.ru/ru/9319" target="_blank">Фирменные поезда</a>
                          </v-list-item>
                          <v-list-item>
                            <a href="https://www.rzd.ru/ru/9317" target="_blank">Классы обслуживания</a>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template></v-text-field
                  >
                  <!-- </div>
                  <div> -->
                  <v-select
                    v-model="order.reference.tariffType"
                    :rules="notEmptyRules"
                    :items="tariffList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Выберите тип тарифа"
                    label="Тип тарифа"
                    type="number"
                  ></v-select>
                  <v-select
                    v-model="order.reference.carType"
                    :rules="notEmptyRules"
                    :items="carList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Выберите тип вагона"
                    label="Тип вагона"
                    type="number"
                  ></v-select>
                  <v-select
                    v-model="order.reference.trainType"
                    :rules="validationRules.trainTyperules"
                    :items="trainList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    hint="Выберите категорию поезда"
                    label="Категория поезда"
                    type="number"
                  ></v-select>
                  <v-menu v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="visibleDateFrom"
                        :rules="notEmptyRules"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        required
                        class="mt-1 mb-3"
                        v-bind="attrs"
                        v-on="on"
                        persistent-hint
                        hint="Выберите дату отправления"
                        label="Дата отправления"
                        ><template v-slot:append-outer>
                          <v-menu open-on-hover top offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs">
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item>
                                <p>На большинство поездов дальнего следования глубина продажи билетов 45 суток с начальных станций</p>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      no-title
                      color="#e21a1a"
                      :max="showMaxDate"
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      :min="showPresentDate"
                      v-model="order.reference.departDate"
                      @input="datePickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                    v-model="order.comment"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    hint="Введите комментарий (при необходимости)"
                    label="Комментарий"
                  ></v-text-field>
                  <!-- </div> -->
                  <!-- </div> -->
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <hr class="d-flex d-sm-none mx-3" />
              <v-card height="100%" min-width="230px" :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-title class="Header">Введите контактные данные</v-card-title>
                <v-card-text>
                  <span class="font-weight-medium">Получатель</span>
                  <v-text-field
                    v-model="order.fullName"
                    :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
                    autocomplete="name"
                    hide-details="auto"
                    class="mt-1 mb-0"
                    single-line
                    outlined
                    dense
                    required
                    persistent-hint
                    label="Отображаемое имя"
                    hint="Введите данные контактного лица"
                  ></v-text-field>
                  <p class="nameDescription">
                    Введите фамилию и инициалы в соответствии с документом на который оформляется проезд.
                    <span class="warningSpan"
                      >*
                      <br />
                      Если ФИО в документе указаны на латинице - указать фамилию и инициалы на латинице.
                    </span>
                  </p>

                  <span class="font-weight-medium">Почта</span>
                  <v-text-field
                    v-model="order.mail"
                    :rules="validationRules.notEmpty.concat(validationRules.emailForm)"
                    autocomplete="email"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    type="mail"
                    label="Email"
                    persistent-hint
                    hint="Введите почту"
                  ></v-text-field>
                  <span class="font-weight-medium">Телефон</span>
                  <v-text-field
                    v-model="order.phone"
                    :rules="validationRules.numberForm"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    required
                    label="Номер"
                    persistent-hint
                    hint="Введите номер телефона"
                    type="number"
                    hide-spin-buttons
                    value="+"
                    min="0"
                  ></v-text-field>
                </v-card-text>
                <div class="BannedStation" v-if="isStationBanned" color="red">
                  Для получения справки по поездам сообщением с Республикой Крым и г. Севастополем необходимо обратиться к перевозчикам АО ТК «Гранд Сервис
                  Экспресс» и ООО «ЮППК».
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <hr class="d-flex d-sm-none mx-3" />
              <v-card :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-actions class="align-items-center justify-space-around flex-wrap">
                  <v-col>
                    <div>
                      <v-btn
                        :loading="loading"
                        :disabled="!enabled || isStationBanned"
                        type="submit"
                        depressed
                        color="#e21a1a"
                        class="white--text mr-3 text-RussianRail"
                        v-on:click="createOrderClick"
                        >Оплатить</v-btn
                      >
                      <span>К оплате 200 руб.</span>
                    </div>
                    <div>
                      <v-checkbox v-model="enabled" label="Согласие с условиями оферты" hide-details class="shrink mr-2 mt-0"></v-checkbox>
                      <a href="Оферта.pdf" target="_blank">Условия оферты</a>
                    </div>
                  </v-col>
                  <v-btn text class="mr-3 text-RussianRail" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import moment from 'moment';
export default {
  props: {
    user: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      order: {
        fullName: '',
        mail: '',
        phone: '',
        comment: '',
        referenceType: 1,
        reference: {
          departDate: '',
          carType: '',
          tariffType: '',
          trainNumber: '',
          departStationCode: '',
          arrivalStationCode: '',
          seatType: '',
          trainType: ''
        }
      },
      validationRules: {
        notEmpty: [v => !!v || 0 || 'Обязательное поле'],
        emailForm: [
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        nameForm: [
          value => {
            const pattern = /[ЁёА-я,A-Za-z .-]+$/;
            return pattern.test(value) || 'Неверный формат';
          }
        ],
        numberForm: [v => (v.length > 10 && v.length < 12) || 'Проверьте правильность указанного номера'],
        checkTrainNumber: [
          value => {
            const pattern = /^((\d{3})+([А-я])?)$/;
            return pattern.test(value) || 'Неверный формат (Пример: 001 или 001А)';
          }
        ],
        trainTyperules: [
          value => {
            return this.testTrainNumberType(value) || 'Номер поезда не совпадает с категорией поезда';
          }
        ]
      },
      dialog: false,
      loading: false,
      datePickerMenu: false,
      notEmptyRules: [v => !!v || 0 || 'Обязательное поле'],
      enabled: false,
      fillAllForm: false,
      showErrorMessage: false,
      referenceDialog: false
    };
  },
  computed: {
    showPresentDate() {
      return moment()
        .format()
        .substring(0, 10);
    },
    showMaxDate() {
      return moment()
        .add(120, 'd')
        .format()
        .substring(0, 10);
    },
    visibleDateFrom() {
      return this.changeDateForm(this.order.reference.departDate);
    },
    isStationBanned() {
      let isBanned = false;
      if (this.order.reference.departStationCode >= 2078000 && this.order.reference.departStationCode <= 2079999) {
        isBanned = true;
      }
      if (this.order.reference.arrivalStationCode >= 2078000 && this.order.reference.arrivalStationCode <= 2079999) {
        isBanned = true;
      }
      return isBanned;
    },
    showTrainType() {
      return this.defineTrainType(this.order.reference.trainNumber);
    },
    ...mapGetters('reference', ['createReferenceResult']),
    ...mapGetters('reference', ['createReferenceMessage']),
    ...mapGetters('reference', ['paymentToken']),
    ...mapGetters('reference', ['orderNumber'])
  },
  methods: {
    stationList() {
      return ReferenceHelper.getStationList();
    },
    tariffList() {
      return ReferenceHelper.getTariffTypeList();
    },
    carList() {
      return ReferenceHelper.getCarTypeList();
    },
    seatList() {
      return ReferenceHelper.getSeatTypeList();
    },
    trainList() {
      return ReferenceHelper.getTrainTypeList();
    },
    checkInput() {
      return true;
    },
    getDay(date) {
      const daysOfWeek = ReferenceHelper.getDateList();
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    showInUpperCase() {
      this.order.reference.trainNumber = this.order.reference.trainNumber.toUpperCase();
    },
    createOrderClick() {
      if (this.$refs.productForm.validate()) {
        this.createOrder({ order: this.order }).then(() => {
          if (this.createReferenceResult === true) {
            this.closeDialog();
            this.$router.push('/payment?token=' + this.paymentToken + '&order=' + this.orderNumber);
          }
        });
      }
    },
    closeDialog() {
      this.order.fullName = '';
      this.order.mail = '';
      this.order.phone = '';
      this.order.reference.departDate = '';
      this.order.reference.carType = '';
      this.order.reference.tariffType = '';
      this.order.reference.trainNumber = '';
      this.order.reference.departStationCode = '';
      this.order.reference.arrivalStationCode = '';
      this.order.reference.seatType = '';
      this.dialog = false;
      this.enabled = false;
    },
    closeReferenceDialog() {
      this.referenceDialog = false;
    },
    defineTrainType(trainNumber) {
      let num = trainNumber.slice(0, 3);
      if ((num >= 1 && num <= 298) || (num >= 801 && num <= 898)) {
        return 'СКОРЫЙ';
      } else if ((num >= 301 && num <= 598) || (num >= 601 && num <= 698)) {
        return 'ПАССАЖИРСКИЙ';
      }
      if (num >= 701 && num <= 750) {
        return 'СКОРОСТНОЙ';
      }
      if ((num >= 751 && num <= 788) || (num >= 601 && num <= 898)) {
        return 'ВЫСОКОСКОРОСТНОЙ';
      } else return '';
    },
    testTrainNumberType(trainType) {
      const currentTrainType = this.defineTrainType(this.order.reference.trainNumber);
      return currentTrainType === trainType;
    },
    onChangeTrainNumber() {
      const pattern = /^((\d{3})+([А-Я])?)$/;
      if (pattern.test(this.order.reference.trainNumber)) {
        this.setTrainType();
      }
    },
    setTrainType() {
      const trainType = this.defineTrainType(this.order.reference.trainNumber);
      this.order.reference.trainType = trainType;
    },
    changeDateForm(pickerDate) {
      return pickerDate
        .split('-')
        .reverse()
        .join('.');
    },
    ...mapActions('reference', ['createOrder'])
  }
};
</script>
<style>
.nameDescription {
  font-size: 0.85em;
  letter-spacing: 0.1px;
  line-height: 1.2;
}
.warningSpan {
  color: red;
}
.Price {
  white-space: nowrap;
}
.Header {
  font-family: FsRail;
}
.BannedStation {
  color: red;
  padding: 15px;
}
.file-upload-card {
  padding: 20px;
  /* background-color: rgb(211, 211, 211) !important; */
  font-family: RussianRail;
}
.ReferenceTravelScopeMain {
  font-weight: 400;
}
.DataOfTicketCol {
  flex-basis: 30%;
}
.Line {
  margin-left: 3%;
  margin-right: 3%;
}
.VipUploadButtonIcon {
  margin-right: 10px;
}
.VipUploadButtonText {
  color: #e21a1a;
}
.VipUploadButtonCancelText {
  color: gray;
}
.VipUploadBlockSpacer {
  margin-top: 20px;
}
.VipUploadAlertBlock {
  margin-top: 20px;
  color: #c00000;
}
.VipUploadPanelHeaderWarning {
  font-weight: bold;
  color: #ed7d31;
}
.VipUploadPanelHeaderError {
  font-weight: bold;
  color: #c00000;
}
.VipUploadHintText {
  color: #606060;
}
.VipUploadPicker {
  border-radius: 5px;
  color: white;
  font-weight: 400;
  box-shadow: 0 2px 2px #8eaadb;
  border: solid 1px #2f5496;
  margin: 20px 0 10px 0;
  padding: 2px;
}
.ReferenceButtonWrapper {
  /* border: solid 1px #e21a1a; */
  border-radius: 6px;
}
.VipUploadButtonCancelWrapper {
  border: solid 1px gray;
  border-radius: 6px;
  margin-left: 20px;
}
.VipUploadFileText {
  color: black;
}
.VipUploadCheckResult {
  display: flex;
  //justify-content: space-between;
  margin: 20px 0 0 0;
}
.VipUploadProcessList {
  display: flex;
  margin: 0 40px 0 0;
}
.VipUploadProcessListNumber {
  font-size: 42px;
  color: #2f5496;
  line-height: 1;
}
.VipUploadProcessListText {
  font-size: 14px;
  color: #8eaadb;
  margin-left: 16px;
}
.VipUploadProcessListNumberTotal {
  font-size: 42px;
  color: black;
  line-height: 1;
}
.VipUploadProcessListTextTotal {
  font-size: 14px;
  color: #a6a6a6;
  margin-left: 16px;
}
.VipUploadProcessListNumberWarning {
  font-size: 42px;
  color: #ed7d31;
  line-height: 1;
}
.VipUploadProcessListTextWarning {
  font-size: 14px;
  color: #f4b083;
  margin-left: 16px;
}
.VipUploadProcessListNumberError {
  font-size: 42px;
  color: #c00000;
  line-height: 1;
}
.VipUploadProcessListTextError {
  font-size: 14px;
  color: #ff4b4b;
  margin-left: 16px;
}
.VipUploadTableRowWarningFirst {
  background-color: #fbe4d5;
  padding: 0 6px 0 6px;
}
.VipUploadTableRowWarningSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}
.VipUploadTableRowErrorFirst {
  background-color: #ffc5c5;
  padding: 0 6px 0 6px;
}
.VipUploadTableRowErrorSecond {
  background-color: white;
  padding: 0 6px 0 6px;
}
.VipUploadTableHeaderWarning {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #ed7d31;
}
.VipUploadTableHeaderError {
  color: black;
  //font-size: small;
  font-weight: bold;
  //background-color: #8ab0d2;
  padding: 0 6px 0 6px;
  //border-radius: 5px;
  border-bottom: solid 1px #c00000;
}
</style>
