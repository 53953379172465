<template>
  <div class="ReferenceShortRouteMain">
    <v-dialog v-model="dialog" max-width="1200" persistent :fullscreen="$vuetify.breakpoint.xs">
      <template v-slot:activator="{ on }">
        <v-row class="pa-4 ma-0 align-center">
          <v-col md="1" sm="1" cols="4" align-center py-0>
            <div class="d-flex justify-center">
              <v-dialog v-model="referenceDialog" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon plain v-bind="attrs" v-on="on">
                    <v-icon color="grey" x-large left>mdi-text-box-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <v-card justify-center>
                  <v-img height="100%" src="../../../public/img/ShortRoute.png" />
                  <v-card-actions class="align-items-center justify-space-around flex-wrap">
                    <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeReferenceDialog">Закрыть</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <v-col md="7" sm="6" cols="8">
            <div>
              <div class="grey--text text-none mr-3">Справка</div>
              <div>о проезде в поездах дальнего следования по кратчайшему маршруту следования</div>
            </div>
          </v-col>
          <v-col md="2" sm="3" cols="6">
            <div class="d-flex align-center justify-center mr-2">
              <h2 class="Price">200 руб.</h2>
            </div>
          </v-col>
          <v-col md="2" min-width="150" sm="2" cols="6">
            <div>
              <v-btn v-on="on" class="white--text mr-3 text-RussianRail" depressed color="#e21a1a">Выбрать</v-btn>
            </div>
          </v-col>
        </v-row>
        <hr class="Line" />
      </template>
      <v-card class="file-upload-card px-4" :color="$vuetify.breakpoint.xs ? '#ffffff' : '#d3d3d3'" outlined>
        <v-form ref="productForm" @submit.prevent="checkInput">
          <v-row>
            <v-col>
              <v-card :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <!-- <v-card-title class="justify-center">Оформление заказа</v-card-title> -->
                <v-card-title class=" d-flex justify-center Header"
                  >Справка о проезде в поездах дальнего следования по кратчайшему маршруту следования</v-card-title
                >
              </v-card>
            </v-col>
          </v-row>
          <hr class="d-flex d-sm-none mx-3" />
          <v-row>
            <v-col class="DataOfTicketCol">
              <v-card height="100%" min-width="230px" :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-title class="Header">Введите данные</v-card-title>
                <v-card-text>
                  <v-autocomplete
                    v-model="order.reference.departStationCode"
                    :rules="notEmptyRules"
                    :items="stationList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    hint="Выберите станцию отправления"
                    label="Станция отправления"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="order.reference.arrivalStationCode"
                    :rules="notEmptyRules"
                    :items="stationList()"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    hint="Выберите станцию прибытия"
                    label="Станция прибытия"
                  ></v-autocomplete>
                  <v-menu v-model="datePickerMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="notEmptyRules"
                        hint="Выберите дату отправления"
                        label="Дата отправления"
                        prepend-icon="mdi-calendar"
                        persistent-hint
                        v-model="visibleDateFrom"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        ><template v-slot:append-outer>
                          <v-menu open-on-hover top offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs">
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item>
                                <p>На большинство поездов дальнего следования глубина продажи билетов 45 суток с начальных станций</p>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template></v-text-field
                      >
                    </template>
                    <v-date-picker
                      color="#e21a1a"
                      no-title
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      :max="showMaxDate"
                      :min="showPresentDate"
                      v-model="order.reference.departDate"
                      @input="datePickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                    v-model="order.comment"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    hint="Введите комментарий (при необходимости)"
                    label="Комментарий"
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <hr class="d-flex d-sm-none mx-3" />
              <v-card height="100%" min-width="230px" :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-title class="Header">Введите контактные данные</v-card-title>
                <v-card-text>
                  <span class="font-weight-medium">Получатель</span>
                  <v-text-field
                    v-model="order.fullName"
                    :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
                    autocomplete="name"
                    hide-details="auto"
                    class="mt-1 mb-0"
                    single-line
                    outlined
                    dense
                    persistent-hint
                    label="Отображаемое имя"
                    hint="Введите данные контактного лица"
                  ></v-text-field>
                  <p class="nameDescription">
                    Введите фамилию и инициалы в соответствии с документом на который оформляется проезд.
                    <span class="warningSpan"
                      >*
                      <br />
                      Если ФИО в документе указаны на латинице - указать фамилию и инициалы на латинице.
                    </span>
                  </p>
                  <span class="font-weight-medium">Почта</span>
                  <v-text-field
                    v-model="order.mail"
                    :rules="validationRules.notEmpty.concat(validationRules.nameForm)"
                    autocomplete="email"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    type="mail"
                    label="Email"
                    persistent-hint
                    hint="Введите почту"
                  ></v-text-field>
                  <span class="font-weight-medium">Телефон</span>
                  <v-text-field
                    v-model="order.phone"
                    :rules="validationRules.numberForm"
                    autocomplete="tel"
                    hide-details="auto"
                    class="mt-1 mb-3"
                    single-line
                    outlined
                    dense
                    type="number"
                    persistent-hint
                    hint="Введите номер телефона"
                    label="Номер"
                    hide-spin-buttons
                  ></v-text-field>
                </v-card-text>
                <div class="BannedStation" v-if="isStationBanned" color="red">
                  Для получения справки по поездам сообщением с Республикой Крым и г. Севастополем необходимо обратиться к перевозчикам АО ТК «Гранд Сервис
                  Экспресс» и ООО «ЮППК».
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <hr class="d-flex d-sm-none mx-3" />
              <v-card :elevation="$vuetify.breakpoint.xs ? '0' : '2'">
                <v-card-actions class="align-items-center justify-space-around flex-wrap">
                  <v-col>
                    <div>
                      <v-btn
                        :loading="loading"
                        :disabled="!enabled || isStationBanned"
                        type="submit"
                        depressed
                        color="#e21a1a"
                        class="white--text mr-3 text-RussianRail"
                        v-on:click="createOrderClick"
                        >Оплатить</v-btn
                      >
                      <span>К оплате 200 руб.</span>
                    </div>
                    <div>
                      <v-checkbox v-model="enabled" label="Согласие с условиями оферты" hide-details class="shrink mr-2 mt-0"></v-checkbox>
                      <a href="Оферта.pdf" target="_blank">Условия оферты</a>
                    </div>
                  </v-col>
                  <v-btn text class="mr-3 text-RussianRail" color="grey darken-3" @click="closeDialog">Отмена</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import ReferenceHelper from '@/helpers/reference/reference.helper';
export default {
  props: {
    user: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      order: {
        fullName: '',
        mail: '',
        phone: '',
        referenceType: 4,
        reference: {
          departStationCode: '',
          arrivalStationCode: '',
          departDate: '',
          comment: ''
        }
      },
      validationRules: {
        notEmpty: [v => !!v || 0 || 'Обязательное поле'],
        emailForm: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Неверный формат';
        },
        nameForm: value => {
          const pattern = /[ЁёА-я,A-Za-z .-]+$/;
          return pattern.test(value) || 'Неверный формат';
        },
        numberForm: [v => (v.length > 10 && v.length < 12) || 'Проверьте правильность указанного номера']
      },
      dialog: false,
      notEmptyRules: [v => !!v || 0 || 'Обязательное поле'],
      referenceDialog: false,
      loading: false,
      datePickerMenu: false,
      enabled: false
    };
  },
  computed: {
    showPresentDate() {
      return moment()
        .format()
        .substring(0, 10);
    },
    showMaxDate() {
      return moment()
        .add(120, 'd')
        .format()
        .substring(0, 10);
    },
    visibleDateFrom() {
      return this.changeDateForm(this.order.reference.departDate);
    },
    isStationBanned() {
      let isBanned = false;
      if (this.order.reference.departStationCode >= 2078000 && this.order.reference.departStationCode <= 2079999) {
        isBanned = true;
      }
      if (this.order.reference.arrivalStationCode >= 2078000 && this.order.reference.arrivalStationCode <= 2079999) {
        isBanned = true;
      }
      return isBanned;
    },
    ...mapGetters('reference', ['createReferenceResult']),
    ...mapGetters('reference', ['createReferenceMessage']),
    ...mapGetters('reference', ['paymentToken']),
    ...mapGetters('reference', ['orderNumber'])
  },
  methods: {
    stationList() {
      return ReferenceHelper.getStationList();
    },
    tariffList() {
      return ReferenceHelper.getTariffTypeList();
    },
    carList() {
      return ReferenceHelper.getCarTypeList();
    },
    seatList() {
      return ReferenceHelper.getSeatTypeList();
    },
    checkInput() {
      return true;
    },
    getDay(date) {
      const daysOfWeek = ReferenceHelper.getDateList();
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    createOrderClick() {
      if (this.$refs.productForm.validate()) {
        this.createOrder({ order: this.order }).then(() => {
          if (this.createReferenceResult === true) {
            this.closeDialog();
            this.$router.push('/payment?token=' + this.paymentToken + '&order=' + this.orderNumber);
          }
        });
      }
    },
    closeDialog() {
      this.dialog = false;
      this.enabled = false;
      this.order.fullName = '';
      this.order.mail = '';
      this.order.phone = '';
      this.order.reference.departStationCode = '';
      this.order.reference.arrivalStationCode = '';
      this.order.reference.departDate = '';
    },
    closeReferenceDialog() {
      this.referenceDialog = false;
    },
    changeDateForm(pickerDate) {
      return pickerDate
        .split('-')
        .reverse()
        .join('.');
    },
    ...mapActions('reference', ['createOrder'])
  }
};
</script>
<style scoped>
.nameDescription {
  font-size: 0.85em;
  letter-spacing: 0.1px;
  line-height: 1.2;
}
.warningSpan {
  color: red;
}
.Price {
  white-space: nowrap;
}
.Header {
  font-family: FsRail;
}
.BannedStation {
  color: red;
  padding: 15px;
}
.ReferenceShortRouteButtonText {
  color: #e21a1a;
}
.DataOfTicketCol {
  flex-basis: 30%;
}
.file-upload-card {
  padding: 20px;
  /* background-color: rgb(211, 211, 211); */
  font-family: RussianRail;
}
.Line {
  margin-left: 3%;
  margin-right: 3%;
}
.ReferenceShortRouteButtonWrapper {
  border: solid 1px #e21a1a;
  border-radius: 6px;
  width: min-content;
}
</style>
