<template>
  <div class="ReferenceInfoMain">
    <v-dialog v-model="dialog" max-width="1280">
      <template v-slot:activator="{ on, attrs }">
        <v-row ml="5">
          <v-col class="d-flex align-center pl-6 mr-auto">
            <v-btn class="Button" icon plain v-bind="attrs" v-on="on">
              <v-icon color="grey" left>mdi-information-outline</v-icon>
              Описание услуг
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title> Добро пожаловать на страницу сервиса получения письменной справки в форме электронного документа.</v-card-title>

        <v-card-text>
          Здесь Вы можете получить*:<br />
          1. Справку о стоимости проезда (в поезде дальнего следования);<br />
          2. Справку о наличии мест (в поезде дальнего следования);<br />
          3. Справку о подтверждении ранее приобретенного (утерянного) проездного документа;<br />
          4. Справку о проезде в поездах дальнего следования по кратчайшему маршруту следования;<br />
          5. Справку об изменении графика движения поездов.<br />
          Услуга выдачи письменной справки в форме электронного документа об изменении графика движения поездов оказывается бесплатно.<br />
          Получение остальных письменных справок в форме электронного документа – платно.<br />
          <h4>Правила получения письменных справок в форме электронного документа</h4>
          1. На странице меню необходимо выбрать необходимую справку.<br />
          2. При выборе платных письменных справок в форме электронного документа и заполнения необходимых данных, Вы будете направлены на страницу оплаты
          услуги. <br />
          3. Оплата услуги выдачи письменной справки в форме электронного документа возможна следующими безналичными способами оплаты:<br />
          • с помощью ввода номера банковской карты (ввести в предлагаемое поле номер карты, с которой будет списана указанная сумма к оплате);<br />
          • ЮMoney. <br />
          Услуга предоставляется Дирекцией железнодорожных вокзалов – филиалом открытого акционерного общества «Российские железные дороги».<br />
          В справке о проезде в поездах дальнего следования по кратчайшему маршруту следования расчет производится по минимальному<br />
          километражу, затрачиваемому при следовании со станции отправления до станции назначения.<br />
          В справке о стоимости проезда указывается минимальная полная стоимость билета на момент выдачи справки.<br />
          При необходимости получения справки о несвоевременном отправлении поезда следует обратиться к Перевозчику.<br />
          <br />
          * Справки по поездам, курсирующим по территории Республики Крым и г. Севастополь,<br />
          вы можете получить в кассах перевозчиков АО ТК «Гранд Сервис Экспресс» и ООО «ЮППК».</v-card-text
        >
        <v-card-actions class="align-items-center justify-space-around flex-wrap">
          <v-btn text class="mx-2 text-none" color="grey darken-3" @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    }
  }
};
</script>
<style scoped>
.ReferenceInfoMain {
  /* display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse; */
}
.Button {
  margin-left: 100px;
}
</style>
